import {useMemo} from 'react'
import {Color as BWColor} from './BW/colors'
import {fontFamily as BWFontFamily} from './BW/font'
import {LineHeight as BWLineHeight} from './BW/lineHeight'
import StyledBB from './BW/styledComponent'
import {Color as LJAEColor} from './LJ-AE/colors'
import {fontFamily as LJAEFontFamily} from './LJ-AE/font'
import {LineHeight as LJAELineHeight} from './LJ-AE/lineHeight'
import StyledLJAE from './LJ-AE/styledComponent'
import {Color as LJColor} from './LJ/colors'
import {fontFamily as LJFontFamily} from './LJ/font'
import {LineHeight as LJLineHeight} from './LJ/lineHeight'
import StyledLJ from './LJ/styledComponent'
import {Color as MMColor} from './MM/colors'
import {fontFamily as MMFontFamily} from './MM/font'
import {LineHeight as MMLineHeight} from './MM/lineHeight'
import StyledMM from './MM/styledComponent'
import {Size} from './size'
import {IBrand, IColor} from './types'
import {StyledGridGlobalStyles} from './GridStyles'

const color: Record<IBrand, IColor> = {
  lj: LJColor,
  'lj-co': LJColor,
  'lj-sa': LJColor,
  'lj-ae': LJAEColor,
  bw: BWColor,
  'bw-ae': BWColor,
  mm: MMColor,
  'mm-ae': MMColor,
  'bw-co': BWColor,
  'mm-co': MMColor,
}

const lineHeight: Record<IBrand, string> = {
  lj: LJLineHeight,
  'lj-co': LJLineHeight,
  'lj-sa': LJLineHeight,
  'lj-ae': LJAELineHeight,
  bw: BWLineHeight,
  'bw-ae': BWLineHeight,
  mm: MMLineHeight,
  'mm-ae': MMLineHeight,
  'bw-co': BWLineHeight,
  'mm-co': MMLineHeight,
}

const fontFamily: Record<IBrand, string> = {
  lj: LJFontFamily,
  'lj-co': LJFontFamily,
  'lj-sa': LJFontFamily,
  'lj-ae': LJAEFontFamily,
  bw: BWFontFamily,
  'mm-ae': MMFontFamily,
  'bw-ae': BWFontFamily,
  'bw-co': BWFontFamily,
  mm: MMFontFamily,
  'mm-co': MMFontFamily,
}

const styledComponentBrand: Record<IBrand, any> = {
  lj: StyledLJ,
  'lj-co': StyledLJ,
  'lj-sa': StyledLJ,
  'lj-ae': StyledLJAE,
  bw: StyledBB,
  'bw-ae': StyledBB,
  mm: StyledMM,
  'mm-ae': StyledMM,
  'bw-co': StyledBB,
  'mm-co': StyledMM,
}

const BrandTheme = (props: {brand: IBrand}) => {
  const {brand = 'lj'} = props
  const Component = styledComponentBrand[brand]

  const theme = useMemo(() => {
    return {
      color: color[brand],
      lineHeight: lineHeight[brand],
      sizes: Size,
      fontFamily: fontFamily[brand],
    }
  }, [brand])

  return (
    <>
      <Component theme={theme} />
      <StyledGridGlobalStyles />
    </>
  )
}

export {BrandTheme}
